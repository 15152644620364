/* node_modules */
import React, { useEffect, useState } from 'react';
import {
  NavLink,
} from 'react-router-dom';
import { connect } from 'react-redux';

/* local imports */
import {
  fetchUser,
  getUserByName,
  FETCHING_USER,
} from '~/modules/user';

import {
  fetchImages,
  fetchFolders,
  deleteFolder,
  updateFolder,
  getImagesByUser,
  getImagesByFolder,
  getImagesBySection,
  FETCHING_IMAGES,
  getFolders,
} from '~/modules/gallery';

import { addFlash } from '~/modules/flashes';
import { getVisibilitySettings } from '~/modules/contentVisibility';
import { isLoading } from '~/modules/loading';

import FormattedMessage from '~/components/common/FormattedMessage';
import ImageSet from '~/components/Gallery/ImageSet';
import Loading from '~/components/Loading';
import AdultToggle from '~/components/AdultToggle';
import PasswordPrompt from '../PasswordPrompt';
import Button from '~/components/Button';
import { withRouter } from '~/hooks';

import UserCard from './UserCard';
import FolderSet from './FolderSet';

/* style imports */
import styles from './Page.scss';
import UserGallery from '~/components/UserGallery';
import Folder from '~/components/Gallery/Folder';

/**
 * Displays a single user's gallery
 */


export function GalleryPage({
  dispatch,
  location: {
    pathname,
  },
  isSignedIn,
  currentUser,
  params,
  user,
  gallery,
  images,
  folders,
  isLoading,
  adultToggleState,
}) {
  const { folderId } = params;

  const isFavorites = pathname.endsWith('favorites');
  
  useEffect(() => {
    dispatch(fetchUser(params.username));
  }, [params.username]);


  if (isLoading.user || !user) return <Loading />;
  
  return (
    <div className={styles.GalleryPage}>
      {(isLoading.user || !user || !user.gallery) ? <Loading /> : (
        <UserCard {...{
          isSignedIn, currentUser, user,
        }}
        />
      )}

      <AdultToggle />

      {
        !folderId && (
          <div className={styles.GalleryPage__Navigation}>
            <NavLink to={isFavorites ? pathname.replace('/favorites', '') : pathname} className={({ isActive }) => (isActive ? styles.GalleryPage__NavigationLinkActive : '')}>
              <FormattedMessage
                id="ImageGallery_Images"
                defaultMessage="Images"
              />
            </NavLink>
            <span>—</span>
            <NavLink to={isFavorites ? pathname : `${pathname}/favorites`} className={({ isActive }) => (isActive ? styles.GalleryPage__NavigationLinkActive : '')}>
              <FormattedMessage
                id="ImageGallery_Favorites"
                defaultMessage="Favorites"
              />
            </NavLink>
          </div>
        )
      }

      {!isFavorites 
        && 
        (user.username)
        && (
          <FolderSet
            user={user}
            showActions={currentUser && (currentUser.role === 'admin' || user.username === currentUser.username)}
            actions={{
              deleteFolder: id => dispatch(deleteFolder(id)),
              protectFolder: (id, password) => dispatch(updateFolder(id, { password })),
            }}
          />
        )}
      {
        (!folderId) && (
          <UserGallery
            user={user?.username}
            showFavorites={isFavorites}
          />
        )
      }
      
      {
        !!folderId && (
          <Folder
            id={folderId}
          />
        )
      }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: {
      user: isLoading(state.loading, FETCHING_USER),
      gallery: isLoading(state.loading, FETCHING_IMAGES),
      ...state.loading,
    },
    user: getUserByName(state, ownProps.params.username),
    gallery: state.gallery,
    images: {
      byUser: getImagesByUser(state, ownProps.params.username),
      bySection: section => getImagesBySection(state, section),
      byFolder: folderId => getImagesByFolder(state, folderId),
    },
    folders: getFolders(state),
    isSignedIn: state.currentUser.isSignedIn,
    currentUser: state.currentUser.data,
    adultToggleState: getVisibilitySettings(state),
    fromId: state.fromId,
  };
};

export default withRouter(connect(mapStateToProps)(GalleryPage));
