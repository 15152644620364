/* node_modules */
import React, { Fragment } from 'react';
import fetch from 'isomorphic-fetch';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

/* local imports */
import config from '~/config';

import FormattedMessage from '~/components/common/FormattedMessage';
import Button, { FollowButton } from '~/components/Button';
import Loading from '~/components/Loading';
import Avatar from '../Avatar';

/* style imports */
import styles from './UserOverview.scss';

/**
 * @type {React.Component<{user: User}>}
 */
export default class UserOverview extends React.Component {
  static propTypes = {
    user: propTypes.shape({
      username: propTypes.string.isRequired,
      avatar: propTypes.exact({
        url: propTypes.string,
      }).isRequired,
    }).isRequired,
    children: propTypes.oneOfType([propTypes.arrayOf(propTypes.node), propTypes.node]),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isAnon: false,
      isLoading: false,
      thumbs: [],
    };

    this.renderThumbs = this.renderThumbs.bind(this);
  }

  componentDidMount() {
    const { user: { username } } = this.props;

    this.setState({ isLoading: true });

    fetch(`${config.api}/users/${username}/gallery?limit=4`)
      .then(res => res.json())
      .then((json) => {
        if (json.status && json.status === 'error') {
          return this.setState({
            isAnon: true,
            isLoading: false,
            thumbs: [],
          });
        }

        return this.setState({
          isLoading: false,
          thumbs: json.data,
        });
      })
      .catch((reason) => {
        console.error(`Error while loading UserOverview: ${reason}`);

        this.setState({ isLoading: false });
      });
  }

  renderThumbs() {
    const { thumbs } = this.state;

    return thumbs.map((thumb) => {
      let thumbUrl = `${thumb.image[thumb.is_video || thumb.is_flash ? 'vthumb' : 'thumb'].url}`;

      if (thumb.is_flash) {
        thumbUrl = '/img/piczelflashth.png';
      }

      return <div className={styles.UserOverview__Thumb} style={{ backgroundImage: `url(${thumbUrl})` }} key={thumb.id} />;
    });
  }

  render() {
    const { user, children } = this.props;
    const { isAnon, isLoading, thumbs } = this.state;

    return (
      <div className={styles.UserOverview}>
        <b className={styles.UserOverview__Username}>{user.username}</b>
        <FollowButton username={user.username} small />
        <Avatar userId={user.id} username={user.username} className={styles.UserOverview__Avatar} />
        <div
          className={styles.UserOverview__Description}
          dangerouslySetInnerHTML={{ __html: user.gallery_description }}
        />
        {(isLoading || (!thumbs))
          ? <Loading size="small" />
          : (
            <Link to={`/gallery/${user.username}`} className={styles.UserOverview__Thumbs}>
              {this.renderThumbs()}
            </Link>
          )}
        <div className={styles.UserOverview__Actions}>
          {!isAnon && (
            <Fragment>
              <Button to={`/watch/${user.username}`} color="black">
                <FormattedMessage
                  id="StreamUserOverview"
                  defaultMessage="Stream"
                />
              </Button>
              <Button to={`/gallery/${user.username}`} color="black">
                <FormattedMessage
                  id="GalleryUserOverview"
                  defaultMessage="Gallery"
                />
              </Button>
            </Fragment>
          )}

          {React.Children.map(children, child => React.cloneElement(child, { isAnon }))}
        </div>
      </div>
    );
  }
}
