import React, {  useEffect, useMemo, useState} from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../Loading";
import ImageSet from "../Gallery/ImageSet";
import Pagination from "../Pagination";

import { getVisibilitySettings } from "~/modules/contentVisibility";

import Config from "~/config";

/**
 * Show a user's gallery (recent uploads) 
 */
export default function UserGallery({
  user,
  showFavorites = false,
}: {
  user: string;
  showFavorites: boolean;
}) {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.currentUser.data);
  const authHeaders = useSelector((state: RootState) => state.auth);
  const adultToggleState = useSelector(getVisibilitySettings);

  const { data, isLoading, error } = useQuery({
    queryKey: ['user-gallery', user, page, showFavorites], 
    queryFn: () => {
      const url = showFavorites ? `${Config.api}/gallery/favorites/${user}` : `${Config.api}/users/${user}/gallery`;

      return axios.get<{
        data: GalleryImage[],
        meta: PaginationMeta,
      }>(url, { 
        headers: authHeaders,
        params: {
          page,
        } 
      }).then(res => res.data);
  }});

  useEffect(() => {
    setPage(1);
  }, [user, showFavorites]);

  const needsPagination = useMemo(() => {
    return data && data?.meta.total_pages > 1;
  }, [data]);

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div>
      {
        needsPagination && (
          <Pagination
            page={page}
            totalPages={data?.meta.total_pages}
            onChange={setPage}
          />
        )
      }

      {isLoading ? <Loading /> : (
        <ImageSet dispatch={dispatch} currentUser={currentUser} images={data.data} adultToggleState={adultToggleState} />
      )}

      {
        needsPagination && (
          <Pagination
            page={page}
            totalPages={data?.meta.total_pages}
            onChange={setPage}
          />
        )
      }
    </div>
  )
}