import React, {  useEffect, useMemo, useState} from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../Loading";
import ImageSet from "../Gallery/ImageSet";
import Pagination from "../Pagination";

import { getVisibilitySettings } from "~/modules/contentVisibility";

import Config from "~/config";
import PasswordPrompt from "~/views/Gallery/PasswordPrompt";

export default function Folder({
  id
}: {
  id: string;
}) {
  const [promptingPassword, setPromptingPassword] = useState(false);
  const [password, setPassword] = useState('');

  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.currentUser.data);
  const authHeaders = useSelector((state: RootState) => state.auth);
  const adultToggleState = useSelector(getVisibilitySettings);

  const { data, isLoading, error } = useQuery({
    queryKey: ['gallery-folder', id, page, password],
    enabled: !promptingPassword || !!password,
    initialData: {
      data: [],
      meta: {
        total_pages: 1,
      }
    }, 
    queryFn: () => axios.get<{
      data: GalleryImage[],
      meta: PaginationMeta,
    }>(`${Config.api}/gallery/folder/${id}`, { 
      headers: authHeaders,
      params: {
        page,
        password,
      } 
    }).then(res => res.data).catch(err => {
      if (err.response.status === 403) {
        setPromptingPassword(true);
        setPassword('');
      }

      return {
        data: [],
        meta: {
          total_pages: 1,
        }
      }
    })});

  useEffect(() => {
    setPage(1);
  }, [id]);

  const needsPagination = useMemo(() => {
    return data && data?.meta?.total_pages > 1;
  }, [data]);

  if ((isLoading || !data) && !promptingPassword) {
    return <Loading />;
  }

  if (promptingPassword) {
    return (
      <PasswordPrompt 
        handleSubmit={values => {
          setPassword(values.password);
          setPromptingPassword(false);
        }} 
      />
    );
  }

  return (
    <div>
       {
        needsPagination && (
          <Pagination
            page={page}
            totalPages={data?.meta.total_pages!}
            onChange={setPage}
          />
        )
      }

      {
        isLoading ? <Loading /> : (
          <ImageSet dispatch={dispatch} currentUser={currentUser} images={data!.data} adultToggleState={adultToggleState} />
        )
      }

      {
        needsPagination && (
          <Pagination
            page={page}
            totalPages={data?.meta.total_pages!}
            onChange={setPage}
          />
        )
      }
    </div>
  );
}