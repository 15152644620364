import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';

import Button from '~/components/Button';
import TextField from '~/components/Form/TextField';

import styles from './FolderSet.scss';
import { fetchFolders, resetFolders, getFolders } from '~/modules/gallery';

const cx = classNames.bind(styles);

/**
 * Displays a single folder
 */
export const Folder = ({ id, name, user: { username }, password_protected, thumbnail, adultToggleState, showActions, actions }) => {
  const [isEditing, setEditing] = useState(false);

  return (
    <NavLink to={`/gallery/${username}${id ? `/${id}` : ''}`} className={({ isActive }) => (isActive ? `${styles.Folder} ${styles.FolderActive}` : styles.Folder)}>
      {thumbnail && !password_protected && (
      <div className={cx('Folder__Preview', {
        'Folder__Preview--blurred': thumbnail.nsfw && adultToggleState <= 2,
      })}>
        <img src={thumbnail.image[thumbnail.is_video || thumbnail.is_flash ? 'vthumb' : 'thumb'].url} alt={thumbnail.title} />
      </div>
      )}
      {showActions && (
        <div className={styles.Folder__Actions}>
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              actions.deleteFolder(id);
            }}
          >
            <i className="ion-trash-a" title="Delete Folder" />
          </span>
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (password_protected) {
                actions.protectFolder(id, null);
              } else {
                setEditing(!isEditing);
              }
            }}
          >
            {password_protected
              ? <i className="ion-unlocked" title="Unlock Folder" />
              : <i className="ion-locked" title="Lock Folder" />}
          </span>
        </div>
      )}
      {!isEditing
        ? (
          <div className={styles.Folder__Info}>
            {name}
          </div>
        ) : (
          <div className={styles.Folder__Input}>
            <Formik
              initialValues={{
                password: '',
              }}
              onSubmit={(values) => {
                actions.protectFolder(id, values.password);
                setEditing(false);
              }}
            >
              {({ handleSubmit }) => (
                <Form>
                  <TextField
                    name="password"
                    placeholder="Password"
                  />
                  <Button
                    onClick={handleSubmit}
                    color="flatBlue"
                  >
                    <i className="ion-chevron-right" />
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
    </NavLink>
  );
};

/**
 * Displays a list of folders
 */
export const FolderSet = ({ user, showActions, actions }) => {
  const adultToggleState = useSelector(state => state.contentVisibility);
  const folders = useSelector(getFolders);
  const dispatch = useDispatch();
  
  useEffect(() => {
    console.log(user)
    if (user.username) {
      dispatch(fetchFolders(user.username));
    }
  }, [user, dispatch]);

  return (
    <div className={styles.FolderSet}>
      <Folder name="All uploads" user={user} adultToggleState={adultToggleState} />
      {
      folders.map(folder => (
        <Folder
          {...folder}
          key={`folder:${folder.id}`}
          adultToggleState={adultToggleState}
          {...{ showActions, actions }}
        />
      ))
      }
    </div>
  );
};

export default FolderSet;
