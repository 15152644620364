import React from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames/bind";

import ButtonStyle from '~/components/Button/Default/Button.scss';
import styles from './index.scss';

const cx = classNames.bind(styles);

export default function Pagination({
  page,
  onChange,
  totalPages,
}: {
  page: number;
  onChange: (page: number) => void;
  totalPages: number;
}) {
  return (
    <ReactPaginate
      previousLabel={"<"}
      nextLabel={">"}
      breakLabel={"..."}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={({ selected }) => onChange(selected + 1)}
      forcePage={page - 1}
      containerClassName={cx('Pagination')}
      activeClassName={cx('Pagination__Page--active')}
      activeLinkClassName={cx('Pagination__Page--active', ButtonStyle['Button--blue'])}
      pageLinkClassName={cx(ButtonStyle.Button, ButtonStyle['Button--small'], ButtonStyle['Button--transparent'])}
      pageClassName={cx('Pagination__Page')}
      nextClassName={cx('Pagination__Next', ButtonStyle['Button'], ButtonStyle['Button--small'], ButtonStyle['Button--transparent'])}
      previousClassName={cx('Pagination__Previous', ButtonStyle['Button'], ButtonStyle['Button--small'], ButtonStyle['Button--transparent'])}
      previousLinkClassName={cx('Pagination__Previous', ButtonStyle['Button'], ButtonStyle['Button--small'], ButtonStyle['Button--transparent'])}
      nextLinkClassName={cx('Pagination__Previous', ButtonStyle['Button'], ButtonStyle['Button--small'], ButtonStyle['Button--transparent'])}
      breakClassName={cx('Pagination__Break', ButtonStyle['Button'], ButtonStyle['Button--small'], ButtonStyle['Button--transparent'])}
      breakLinkClassName={cx('Pagination__Previous', ButtonStyle['Button'], ButtonStyle['Button--small'], ButtonStyle['Button--transparent'])}
      disabledLinkClassName={cx(ButtonStyle['Button--disabled'])}
    />
  );
}